import React from 'react';

import { ErrorPage } from '../components/shared/ErrorPage';

const NotFound = () => (
  <ErrorPage
    seoTitle="404: Not found"
    title="Stránka neexistuje"
    description="Vaše skóre tady nedokážeme spočítat."
  />
);

export default NotFound;
